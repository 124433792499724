import { useState, useEffect } from "react";
import useT from '../Traduction'
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { TbArrowNarrowLeft } from "react-icons/tb";
import FileInformations from "../CreateFormat/CreateFormatComposants/FileInformations";
import ResetButton from "../CreateFormat/CreateFormatComposants/ResetButton";
import FileRepository from "../CreateFormat/CreateFormatComposants/FileRepository";
import LinesFromFileTable from "../CreateFormat/CreateFormatComposants/LinesFromFileTable";
import MappingFields from "../CreateFormat/CreateFormatComposants/MappingFields";
import MeasurementUnit from "../CreateFormat/CreateFormatComposants/MeasurementUnit"
import FileOutput from "../CreateFormat/CreateFormatComposants/FileOutput";
import OverviewFormat from "../CreateFormat/CreateFormatComposants/OverviewFormat";

function UpdateFormat({ dataProject }) {
  const t = useT();
  const { name, fileType } = useParams();
  const [formatInfos] = useState({ name, fileType });
  const [errorMessage, setErrorMessage] = useState("");
  const [fileInformationsCompleted, setFileInformationsCompleted] = useState(false);
  const [, setFormatAndSupplierNameCompleted] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [format, setFormat] = useState(null);
  const [fileName, setFileName] = useState("");

  const [countryValue, setCountry] = useState("FR");
  const [languageValue, setLanguage] = useState("FR");
  const [extension, setExtension] = useState("");
  const [lines, setLines] = useState([]);
  const [fileSeparator, setFileSeparator] = useState("");
  const [hasHeader, setHasHeader] = useState(true);
  const [fileEncoding, setFileEncoding] = useState("");
  const [supplier, setSupplier] = useState(null);
  const [lineEnding, setLineEnding] = useState("");
  const [quoteCharacter, setQuoteCharacter] = useState("");
  const [formatName, setFormatName] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [fixedFieldSize, setFixedFieldSize] = useState(false);
  const [fileConfirmed, setFileConfirmed] = useState(false);
  const [fieldMapping, setFieldMapping] = useState([]);
  const [fieldMappingConfirmed, setFieldMappingConfirmed] = useState(false);
  const [fileOutput, setFileOutput] = useState([]);
  const [fileOutputConfirmed, setFileOutputConfirmed] = useState(false);
  const [headersRows, setHeadersRows] = useState([]);
  const [fixedFieldSizeColumnObject, setFixedFieldSizeColumnObject] = useState([]);
  const [skipRows, setSkipRows] = useState(0);
  const [measurementUnitConfirmed, setMeasurementUnitConfirmed] = useState(false);
  const [measurementUnitFields, setMeasurementUnitFields] = useState([]);
  const [heightUnit, setHeightUnit] = useState("");
  const [lengthUnit, setLengthUnit] = useState("");
  const [volumeUnit, setVolumeUnit] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [widthUnit, setWidthUnit] = useState("");
  const [skipEndRows, setSkipEndRows] = useState(0);
  const [selectedSheets, setSelectedSheets] = useState([]);
  const [excelSelectAll, setExcelSelectAll] = useState(false);

  const fetchFormat = async () => {
    await FetchApiFormat(formatInfos, t, dataProject, setErrorMessage, setProgressMessage, setFormat);
  };

  useEffect(() => {
    fetchFormat();
  }, []);

  useEffect(() => {
    if (format) {
      setCountry(format.country ?? "FR");
      setLanguage(format.language ?? "FR");
      setExtension(format.file_type.toUpperCase() ?? "");
      setFileSeparator(format.separator ?? "");
      setHasHeader(format.has_headers ?? true);
      setFileEncoding(format.encoding ?? "");
      setSupplier(format.supplier);
      setQuoteCharacter(format.quoting_char ?? "");
      setFormatName(format.name ?? "");
      setUnitPrice(format.unit_price);
      setFixedFieldSize(format.fixed_field_size === 1 ? true : false);
      setFileOutput(format.file_output ?? []);
      setSkipRows(format.skip_rows ?? 0);
      setHeightUnit(format.unit_height ?? "");
      setLengthUnit(format.unit_length ?? "");
      setVolumeUnit(format.unit_volume ?? "");
      setWeightUnit(format.unit_weight ?? "");
      setWidthUnit(format.unit_width ?? "");
      setSkipEndRows(format.skip_end_rows ?? 0);
      setSelectedSheets(format.excel_sheets_names ?? []);
      setExcelSelectAll(format.excel_sheets_all ?? false);
      setLineEnding(format.end_of_line_chars ?? "");
      const updatedFieldsMapping = Object.fromEntries(
        Object.entries(format.fields_mapping).map(([key, value]) => [
          key,
          value.output_field === 'reference'
            ? { ...value, output_field: 'reference_in_db' }
            : value
        ])
      );
  
      setFieldMapping(updatedFieldsMapping);
    }
  }, [format]);

  const inputFileRepositoryAcceptExtension = "." + extension.toLowerCase();
  const handleBackFileRepository = () => {
    setFileInformationsCompleted(false)
    setHeadersRows([]);
    setLines([]);
  };

  const handleBackLinesFromTable = () => {
    setFileConfirmed(false);
    setHeadersRows([]);
    setLines([]);
  };

  const handleBackMappingFields = () => {
    setFileConfirmed(false);
    setFieldMappingConfirmed(false);
  };

  const handleBackMeasurementUnit = () => {
    setMeasurementUnitFields([]);
    setMeasurementUnitConfirmed(false);
    setFieldMappingConfirmed(false);
  };

  const handleBackFileOutput = () => {
    setMeasurementUnitConfirmed(false);
    setFieldMappingConfirmed(false);
    setFileOutputConfirmed(false);
  };

  const handleBackOverviewFormat = () => {
    setFileOutputConfirmed(false);
  };

  if (!format) {
    return (
      <div className="container-fluid mt-5 d-flex justify-content-center">
        <div className="boxed-create-format">
          <div className="text-center">
            <div className="mt-5">{progressMessage || t("Loading...")}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="boxed-create-format">
        {format && (
          <ResetButton type="update" format={format}setExtension={setExtension} setLines={setLines} setFileSeparator={setFileSeparator} setHasHeader={setHasHeader} setFileInformationsCompleted={setFileInformationsCompleted} setFormatName={setFormatName} setFormatAndSupplierNameCompleted={setFormatAndSupplierNameCompleted} setUnitPrice={setUnitPrice} setFileConfirmed={setFileConfirmed} setFixedFieldSize={setFixedFieldSize} setFieldMapping={setFieldMapping} setFileOutput={setFileOutput} setFieldMappingConfirmed={setFieldMappingConfirmed} setFileOutputConfirmed={setFileOutputConfirmed} setFileEncoding={setFileEncoding} setSupplier={setSupplier} setQuoteCharacter={setQuoteCharacter} setHeadersRows={setHeadersRows} setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject} setCountry={setCountry} setLanguage={setLanguage} setMeasurementUnitConfirmed={setMeasurementUnitConfirmed} setMeasurementUnitFields={setMeasurementUnitFields} setHeightUnit={setHeightUnit} setLengthUnit={setLengthUnit} setVolumeUnit={setVolumeUnit} setWeightUnit={setWeightUnit} setWidthUnit={setWidthUnit} setSkipRows={setSkipRows} setSkipEndRows={setSkipEndRows} setSelectedSheets={setSelectedSheets} setExcelSelectAll={setExcelSelectAll} />
        )}
        <div className="d-flex justify-content-center">
          {!fileInformationsCompleted && (
            <div className="text-center">
              <h2 className="mt-3">{t("File_informations")}</h2>
              <FileInformations
                fileSeparator={fileSeparator}
                setFileSeparator={setFileSeparator}
                hasHeader={hasHeader}
                setHasHeader={setHasHeader}
                setFileInformationsCompleted={setFileInformationsCompleted}
                unitPrice={unitPrice}
                setUnitPrice={setUnitPrice}
                fixedFieldSize={fixedFieldSize}
                setFixedFieldSize={setFixedFieldSize}
                extension={extension}
                formatName={formatName}
                supplier={supplier}
                setCountry={setCountry}
                countryValue={countryValue}
                setLanguage={setLanguage}
                languageValue={languageValue}
                skipRows={skipRows}
                setSkipRows={setSkipRows}
                setFileEncoding={setFileEncoding}
                fileEncoding={fileEncoding}
                skipEndRows={skipEndRows}
                setSkipEndRows={setSkipEndRows}
              />
            </div>
          )}
          {fileInformationsCompleted && headersRows.length === 0 && lines.length === 0 && (
            <div>
              <div className="d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-primary" onClick={handleBackFileRepository}><TbArrowNarrowLeft size={25}/></button>
              </div>
              <h2 className="mt-3">{t("Choose_a_file_to_upload")}</h2>
              <FileRepository
                inputFileRepositoryAcceptExtension={inputFileRepositoryAcceptExtension}
                setLines={setLines} 
                setFileEncoding={setFileEncoding}
                setLineEnding={setLineEnding} 
                setQuoteCharacter={setQuoteCharacter} 
                setFileName={setFileName}
                extension={extension}
                quoteCharacter={quoteCharacter}
                fileSeparator={fileSeparator}
                setHeadersRows={setHeadersRows} 
                setFileSeparator={setFileSeparator}
                hasHeader={hasHeader}
                setHasHeader={setHasHeader}
                fixedFieldSize={fixedFieldSize}
                fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
                setFixedFieldSizeColumnObject={setFixedFieldSizeColumnObject}
                formatName={formatName}
                supplier={supplier}
                skipRows={skipRows}
                setSkipRows={setSkipRows}
                fileEncoding={fileEncoding}
                setSelectedSheets={setSelectedSheets}
                setExcelSelectAll={setExcelSelectAll}
                fieldMapping={fieldMapping}
              />
            </div>
          )}
        </div>
        {headersRows.length > 0 && lines.length > 0 && !fileConfirmed && (
          <div className="mt-3 text-center">
            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleBackLinesFromTable}><TbArrowNarrowLeft size={25}/></button>
            </div>
            <h2 className="mt-3 mb-3">{t("Preview_your_file")}</h2>
            <LinesFromFileTable 
              lines={lines} 
              fileSeparator={fileSeparator} 
              hasHeader={hasHeader} 
              setFileConfirmed={setFileConfirmed}
              fileName={fileName}
              headersRows={headersRows} 
              formatName={formatName}
              supplier={supplier}
              skipRows={skipRows}
              setSkipRows={setSkipRows}
              />
          </div>
        )}
        {fileConfirmed && !fieldMappingConfirmed && (
          <div className="mt-3 text-center">
            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleBackMappingFields}><TbArrowNarrowLeft size={25}/></button>
            </div>
            <h2 className="mt-3">{t("File_mapping")}</h2>
            <MappingFields 
              lines={lines} 
              fileSeparator={fileSeparator} 
              fieldMapping={fieldMapping} 
              setFieldMapping={setFieldMapping}
              fixedFieldSize={fixedFieldSize}
              setFieldMappingConfirmed={setFieldMappingConfirmed}
              headersRows={headersRows} 
              formatName={formatName}
              supplier={supplier}
              fixedFieldSizeColumnObject={fixedFieldSizeColumnObject}
              hasHeader={hasHeader}
              skipRows={skipRows}
              setSkipRows={setSkipRows}
              setMeasurementUnitConfirmed={setMeasurementUnitConfirmed}
              setMeasurementUnitFields={setMeasurementUnitFields}
            />
          </div>
        )}
         {fieldMappingConfirmed && measurementUnitFields && !measurementUnitConfirmed && (
          <div className="mt-3 text-center">
            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleBackMeasurementUnit}><TbArrowNarrowLeft size={25}/></button>
            </div>
            <h2 className="mt-3">{t("Measurement_unit")}</h2>
            <MeasurementUnit 
              fieldMapping={fieldMapping}
              setMeasurementUnitConfirmed={setMeasurementUnitConfirmed}
              measurementUnitFields={measurementUnitFields}
              lengthUnit={lengthUnit} setLengthUnit={setLengthUnit}
              heightUnit={heightUnit} setHeightUnit={setHeightUnit}
              volumeUnit={volumeUnit} setVolumeUnit={setVolumeUnit}
              weightUnit={weightUnit} setWeightUnit={setWeightUnit}
              widthUnit={widthUnit} setWidthUnit={setWidthUnit}
            />
          </div>
        )}
        {fieldMappingConfirmed && measurementUnitConfirmed && !fileOutputConfirmed && (
          <div className="mt-3 text-center">
            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleBackFileOutput}><TbArrowNarrowLeft size={25}/></button>
            </div>
            <h2 className="mt-3">{t("Output_file")}</h2>
            <FileOutput 
              fileOutput={fileOutput}
              setFileOutput={setFileOutput}
              setFileOutputConfirmed={setFileOutputConfirmed}
              formatName={formatName}
              supplier={supplier}
            />
          </div>
        )}
        {fileOutputConfirmed && (
          <div className="mt-3">
            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary" onClick={handleBackOverviewFormat}><TbArrowNarrowLeft size={25}/></button>
            </div>
            <h2 className="mt-3 text-center mb-3">{t("Format_preview_and_validation")}</h2>
            <OverviewFormat 
              setFormatName={setFormatName}
              formatName={formatName} 
              extension={extension}
              hasHeader={hasHeader}
              fileSeparator={fileSeparator}
              fixedFieldSize={fixedFieldSize}
              lineEnding={lineEnding}
              fileEncoding={fileEncoding}
              quoteCharacter={quoteCharacter}
              unitPrice={unitPrice}
              fieldMapping={fieldMapping}
              fileOutput={fileOutput}
              setFileOutput={setFileOutput}
              supplier={supplier}
              countryValue={countryValue}
              languageValue={languageValue}
              dataProject={dataProject}
              skipRows={skipRows}
              lengthUnit={lengthUnit}
              heightUnit={heightUnit}
              volumeUnit={volumeUnit}
              weightUnit={weightUnit}
              widthUnit={widthUnit}
              skipEndRows={skipEndRows}
              selectedSheets={selectedSheets}
              excelSelectAll={excelSelectAll}
            />
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger mt-4 fw-bold">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

async function FetchApiFormat(formatInfos, t, dataProject, setErrorMessage, setProgressMessage, setFormat) {
  setProgressMessage(`${t("retrieving_the_format_in_progess")}..`);
  let url = `${dataProject.startUrl}/get-format?format_name=${formatInfos.name}&file_type=${formatInfos.fileType}`;
  try {
    const response = await fetch(url, {
      headers: {
        "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
        "Content-Type": 'application/json',
      },
      method: 'GET'
    });

    if (!response.ok) {
      if (response.status === 404) {
        setErrorMessage(`${t("The_male")} ${t("format")} ${formatInfos.name} for extension file ${formatInfos.fileType} ${t("does_not_exist")}.`);
      } else if (response.status === 0) {
        setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
      } else if (response.status === 500) {
        setErrorMessage(t("Unexpected_error"));
      } else if (response.status === 401) {
        setErrorMessage(t("Unauthorized"));
        console.log(t("Unauthorized"));
        Auth.signOut();
      } else {
        setErrorMessage(t("Unexpected_error"));
      }
      return;
    }

    const data = await response.json();
    setFormat(data.result.format);
    setProgressMessage("");
  } catch (error) {
    console.error("Error fetching format:", error);
    setErrorMessage(t("Unexpected_error"));
  }
}

export default UpdateFormat;