import React, { useState, useEffect } from "react";
import useT from '../../Traduction';
import { TbTrashXFilled, TbSquareRoundedPlusFilled } from "react-icons/tb";
import { Button } from "react-bootstrap";

function SettingFixedWidthFields({ fixedFieldSizeColumnObject, setFixedFieldSizeColumnObject, setConfirmFixedFieldSettings, hasHeader, formatName, fieldMapping }) {
    const t = useT();
    const [errorMessage, setErrorMessage] = useState("");
    const fixedFieldSizeColumn = {
        name: "",
        start: null,
        width: null,
    }
    const [fixedFieldSizeColumnRows, setFixedFieldSizeColumnRows] = useState([fixedFieldSizeColumn]);
    
    useEffect(() => {
        if (fieldMapping && typeof fieldMapping === 'object' && Object.keys(fieldMapping).length > 0) {
            const newFixedFieldSizeColumnObject = {};
            
            Object.keys(fieldMapping).forEach((key) => {
                const field = fieldMapping[key];
                const width = field.end_of_length - field.start_of_length;
                
                newFixedFieldSizeColumnObject[key] = {
                    name: hasHeader ? field.output_field : key,
                    start: field.start_of_length,
                    width: width
                };
            });
            
            setFixedFieldSizeColumnObject(newFixedFieldSizeColumnObject);
            setFixedFieldSizeColumnRows(Array(Object.keys(newFixedFieldSizeColumnObject).length).fill(fixedFieldSizeColumn));
        }
    }, [fieldMapping, setFixedFieldSizeColumnObject, hasHeader]);
    
    const handleAddFixedFieldSizeColumnRow = () => {
        setFixedFieldSizeColumnRows((prevRows) => [...prevRows, fixedFieldSizeColumn]);
    };
    
    const handleUpdateFixedFieldSizeColumnRow = (key, subKey, value) => {
        setFixedFieldSizeColumnObject((prevFixedFieldSizeColumn) => {
            if (!prevFixedFieldSizeColumn[key]) {
                prevFixedFieldSizeColumn[key] = { ...fixedFieldSizeColumn };
            }
            prevFixedFieldSizeColumn[key][subKey] = value;
            if (!hasHeader) {
                prevFixedFieldSizeColumn[key]["name"] = key;
            } 
            return { ...prevFixedFieldSizeColumn };
        });
    };

    const handleRemoveFixedFieldSizeColumn = (index) => {
        setFixedFieldSizeColumnObject((prevFixedFieldSizeColumn) => {
            const updatedFixedFieldSizeColumn = { ...prevFixedFieldSizeColumn };
            if (updatedFixedFieldSizeColumn[index]) {
              delete updatedFixedFieldSizeColumn[index];
            }
            setFixedFieldSizeColumnRows((prevRows) => prevRows.filter((_, i) => i !== index - 1));
            return updatedFixedFieldSizeColumn;
          });
    };

    const handleConfirmFixedFieldSizeColumnObject = () => {
        setErrorMessage("");
        if (Object.keys(fixedFieldSizeColumnObject).length === 0) {
            setErrorMessage(t("You_must_create_at_least_one_setting"));
        } else {
            let hasFixedFieldSizeColumn = true;
    
            for (const key in fixedFieldSizeColumnObject) {
                const element = fixedFieldSizeColumnObject[key];
                if (element.name === "") {
                    hasFixedFieldSizeColumn = false;
                    setErrorMessage(t("The_name_is_empty_for_a_setting"));
                    break;
                } else if (element.start == null) {
                    hasFixedFieldSizeColumn = false;
                    setErrorMessage(t("Please_enter_a_value_for_the_beginning_of_the_column"));
                    break;
                } else if (element.width == null) {
                    hasFixedFieldSizeColumn = false;
                    setErrorMessage(t("Please_enter_a_value_for_the_column_width"));
                    break;
                } else if (element.width === 0) {
                    hasFixedFieldSizeColumn = false;
                    setErrorMessage(t("An_width_value_is_equal_to_zero"));
                    break;
                }
            }
            
            if (hasFixedFieldSizeColumn) {
                setConfirmFixedFieldSettings(hasFixedFieldSizeColumn);
            }
        }
    };

    return (
        <>
            <h5>{t("Setting_fixed_width_fields")}</h5>
            <div className="mt-3 table-responsive">
                <p>{t("The_format_name_is")} : <span className="fw-bold">{formatName}</span></p>
                <p className="">{t("The_first_start_value_is_0")}.</p>
                <table className="table table-hover text-center table-mapping-file-custom">
                    <colgroup>
                        <col className="col-width-10" />
                        <col className="col-width-10" />
                        <col className="col-width-10" />
                        <col className="col-width-5" />
                    </colgroup>
                    <thead className="thead-mapping-file-custom">
                        <tr>
                            <th className="tr-mapping-file-custom">{t("Header")}</th>
                            <th className="tr-mapping-file-custom">{t("Start_fixed_field")}</th>
                            <th className="tr-mapping-file-custom">{t("Width")}</th>
                            <th className="tr-mapping-file-custom">{t("Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fixedFieldSizeColumnRows.map((row, index) => (
                            <tr key={index + 1}>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={fixedFieldSizeColumnObject[index + 1] ? fixedFieldSizeColumnObject[index + 1].name : (hasHeader ? '' : index)}
                                        onChange={(e) => handleUpdateFixedFieldSizeColumnRow(index + 1, "name", e.target.value)}
                                        placeholder={t("Header")}
                                        disabled={!hasHeader}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={fixedFieldSizeColumnObject[index + 1] ? fixedFieldSizeColumnObject[index + 1].start : null}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? null : parseInt(e.target.value);
                                            handleUpdateFixedFieldSizeColumnRow(index + 1, "start", value);
                                        }}
                                        placeholder={t("Start_fixed_field")}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={fixedFieldSizeColumnObject[index + 1] ? fixedFieldSizeColumnObject[index + 1].width : null}
                                        onChange={(e) => {
                                            const value = e.target.value === "" ? null : parseInt(e.target.value);
                                            handleUpdateFixedFieldSizeColumnRow(index + 1, "width", value);
                                        }}
                                        placeholder={t("Width")}
                                    />
                                </td>
                                <td>
                                    <Button 
                                        className="btn-delete btn-delete-custom-mapping-file"
                                        onClick={() => handleRemoveFixedFieldSizeColumn(index + 1)}
                                    >
                                        <TbTrashXFilled size={20} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="mt-1 d-flex justify-content-center">
                                <Button
                                    onClick={() => handleAddFixedFieldSizeColumnRow()}
                                >
                                    <TbSquareRoundedPlusFilled size={20} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {errorMessage && <div className="d-flex justify-content-center"><div className=" alert alert-warning mt-4 fw-bold center">{errorMessage}.</div></div>}
            <div className="mt-5 d-flex justify-content-center">
                <button type="submit" className="btn" onClick={() => handleConfirmFixedFieldSizeColumnObject()}>
                {t("Confirm_settings")}
                </button>
            </div>
        </>
    );
};

export default SettingFixedWidthFields;