import { useState } from "react";
import { TbTrashXFilled, TbPencil } from "react-icons/tb";
import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import useT from '../Traduction'

function FormReference({ userGroup, dataProject }) {
  const t = useT()
  const [tableData, setTableData] = useState([])
  const [successMessageDeletion, setSuccessMessageDeletion] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const [inProgress, setInProgress] = useState("")
  const handleFeatureNotAvailable = () => {
    setErrorMessage(t("Feature_not_available"));
  };
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const reference = document.getElementById('reference').value.replace(/\s/g, '');

    if (userGroup === "super-admin" || userGroup === "admin" || userGroup === "client") {
      setErrorMessage("")
      if (reference === "") {
        setErrorMessage(t("Please_enter_a_reference") + ".");
        return;
      } else {
        setInProgress(t("Get_reference_in_progress"))
        FetchApiReference(reference, setTableData, setErrorMessage, t, dataProject, setInProgress);
        setSuccessMessageDeletion("");
      }
    } else {
      setErrorMessage(t("Required_rights_to_perform_this_action"));
    }
  };
  return (
    <>    
        <div className="container-fluid mt-5 d-flex justify-content-center">
            <div className="boxed">
                <form onSubmit={handleSubmitForm} className="form-control text-center shadow p-3 mb-5 bg-body rounded form-style">
                    <div className="row gx-5">
                        <div className="col-md-12 my-3">
                            <label htmlFor="reference" className="mb-3 fw-bold">{t('Reference')} :</label>
                            <input type="text" name="reference" id="reference" placeholder={t('Reference')} className="form-control mt-3"/>
                            {errorMessage && (
                                <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>
                            )}
                            {inProgress && (
                              <div className="alert alert-primary mt-4 fw-bold">{inProgress}</div>
                          )}
                        </div>
                    </div>
                    <button type="submit" className="btn  mb-3">{t('Validate')}</button>
                </form>
            </div>
        </div>

        {successMessageDeletion && (
          <div className="alert alert-success text-center mx-auto alert-message fw-bold" role="alert">
            {successMessageDeletion}
          </div>
        )}
        <div className="table-responsive">
            {tableData.length > 0 && (
              <table className="table table-hover table-bordered text-center table-reference-custom">
                <thead>
                  <tr>
                    {/* Required elements */}
                    {Object.keys(tableData[0]).slice(0, 2).map((key, index) => (
                      <th className="th-custom" key={index}>{t(key.charAt(0).toUpperCase() + key.slice(1))}</th>
                    ))}
                    {/* Buttons */}
                    {(userGroup === "super-admin" || userGroup === "admin") && <th className="th-custom"></th>}
                    {/* dataCompletedReference */}
                    {Object.keys(tableData[0]).slice(2, 42).map((key, index) => (
                      <th className="th-custom" key={index}>{t(key.charAt(0).toUpperCase() + key.slice(1))}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                    {tableData.map((data, index) => (
                        <tr key={index}>
                            {/* Required elements */}
                            {Object.values(data).slice(0, 2).map((value, index) => (
                                <td key={index}>{value}</td>
                            ))}
                            {(userGroup === "super-admin" || userGroup === "admin") &&
                              <td>
                                {/* <Link to={`/modification-reference/${data.reference}/${data.supplier}`} state={{ referenceData: getReferenceData(data.reference, data.supplier, tableData) }} className="p-0 mx-2"> */}
                                  <Button className="p-0 mx-1" onClick={() => handleFeatureNotAvailable()}>
                                    <TbPencil size={25} />
                                  </Button>
                                {/* </Link> */}

                                <Button className="p-0 mx-2" onClick={() => handleFeatureNotAvailable()}>
                                    <TbTrashXFilled size={28} />
                                </Button>
                              </td>
                            }
                            {/* dataCompletedReference  */}
                            {Object.values(data).slice(2, 42).map((value, index) => (
                              <td key={index}>{value}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
              </table>
            )}
        </div>
    </>
  )
}

async function FetchApiReference(reference, setTableData, setErrorMessage, t, dataProject, setInProgress) {
  let url = `${dataProject.startUrl}/list-references?reference=${reference}`;
  const response = await fetch(url, {
    headers: {
      "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      "Content-Type": "application/json",
    },
    method: 'GET'
  })
  let dataResultReferences;
  try {
    const data = await response.json();
    dataResultReferences = data.result.references;
  } catch (error) {
    setErrorMessage(t("Unexpected_error"));
  }

  setInProgress("")

  if (!response.ok) {
    if (response.status === 404) {
      setErrorMessage(`${t("The_female")} ${t("reference")} ${reference.toUpperCase()} ${t("does_not_exist")}.`)
    } else if (response.status === 0) {
        setErrorMessage(`${t("Download_failed_due_to_a_connection_problem")}.`);
    } else if (response.status === 500) {
        setErrorMessage(t("Unexpected_error"));
    } else if (response.status === 401) {
        setErrorMessage(t("Unauthorized"));
        console.log(t("Unauthorized"));
        Auth.signOut()
    } else {
        setErrorMessage(t("Unexpected_error"));
    }
  } else {
    setTableData(dataCompletedReference(dataResultReferences));
  }
}   


// function FetchApiDeleteReference(reference, supplier, setTableData, tableData, setSuccessMessageDeletion, t, dataProject, setErrorMessage) {
//   let url = `${dataProject.startUrl}/delete-reference?Reference=${reference}&Supplier=${supplier}`;

//   fetch(url, { 
//     headers: {
//       "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
//     },
//     method: 'DELETE' 
//   })
//     .then(response => {
//       if (!response.ok) {
//         if (response.status === 401) {
//             setErrorMessage(t("Not_authorised"))
//         } else if (response.status === 404) {
//           setErrorMessage((`${t("The_female")} ${t("reference")} ${reference.toUpperCase()} ${t("of")} ${t("supplier")} ${supplier.toUpperCase()} ${t("does_not_exist")}.`))
//         } else {
//           setErrorMessage(t("Unexpected_error"))
//         }
//       }
//       const filteredData = tableData.filter(item => item.reference !== reference || item.supplier !== supplier);
//       setTableData(filteredData);
//       setSuccessMessageDeletion(`${t("The_female")} ${t("reference")} ${reference.toUpperCase()} ${t("of")} ${t("supplier")} ${supplier.toUpperCase()} ${t("has_been_deleted")}.`);
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }


// function getReferenceData(reference, supplier, tableData) {
//   const referenceData = tableData.filter(data => data.reference === reference && data.supplier === supplier);
//   return referenceData[0];
// }

// The order of the data declared here determines the display
function dataCompletedReference(dataResultReferences) {
  return dataResultReferences.map((item) => ({
    reference: item.reference,
    supplier: item.supplier,
    designation: item.designation,
    brand: item.brand,
    purchase_price_et: item.purchase_price_et,
    purchase_price_it: item.purchase_price_it,
    recommended_public_price_et: item.recommended_public_price_et,
    recommended_public_price_it: item.recommended_public_price_it,
    selling_price_et: item.selling_price_et,
    selling_price_it: item.selling_price_it,
    price_sb: item.price_sb,
    vat_rate: item.vat_rate,
    eco_tax: item.eco_tax,
    discount_code: item.discount_code,
    bar_code: item.bar_code,
    bar_code_2: item.bar_code2,
    bar_code_3: item.bar_code3,
    bar_code_4: item.bar_code4,
    family: item.family,
    sub_family: item.sub_family,
    sub_family_2: item.sub_family2,
    sub_family_3: item.sub_family3,
    activity: item.activity,
    year: item.year,
    color: item.color,
    description: item.description,
    materials: item.materials,
    conditioning: item.conditioning,
    country_origin: item.country_origin,
    customs_code: item.customs_code,
    manufacturer_reference: item.manufacturer_reference,
    replacement: item.replacement,
    url_photo: item.url_photo,
    ref_type: item.ref_type,
    size: item.size,
    length: item.length,
    volume: item.volume,
    weight: item.weight,
    width: item.width,
    height: item.height,
    updated_at: item.updated_at,
    created_at: item.created_at
  }));
}
  

export default FormReference