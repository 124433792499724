import React, { useState, useEffect } from 'react';
import useT from '../../components/Traduction'
import { Auth } from 'aws-amplify';
import { TbTrashXFilled, TbEdit } from "react-icons/tb";
import { Button } from "react-bootstrap";
import CallListSuppliers from "../ListSuppliers/CallListSuppliers"
import { useNavigate } from 'react-router-dom';

function FormatsBySupplierComponent({ userGroup, dataProject }) {
  const t = useT();
  const navigate = useNavigate();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierInput, setSupplierInput] = useState('');
  const [successMessageDeletion, setSuccessMessageDeletion] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [inProgress, setInProgress] = useState('')
  const [formats, setFormats] = useState([])
  useEffect(() => {
    const fetchListSupplierAsync = async () => {
      const response = await CallListSuppliers(dataProject);
      if (typeof(response) === "string") {
        if (response === "Unauthorized") {
          Auth.signOut()
        }
        return []
      } else if (Array.isArray(response)) {
        setSupplierNameList(response)
        return response;
      }
    }
    fetchListSupplierAsync().then(supplierList => {
      setSupplierNameList(supplierList);
    });
  }, []);

  const handleInputSupplier = (event) => {
    setSuccessMessageDeletion("")
    setErrorMessage("");
    const inputSupplier = event.target.value.toUpperCase();
    const supplierForFormat = inputSupplier;
    setSupplierInput(supplierForFormat)
  };

  const handleSubmitForm = async (event) => {
    setSuccessMessageDeletion("")
    event.preventDefault();

    if (userGroup === 'super-admin' || userGroup === 'admin') {
      setErrorMessage('')
      if (supplierInput === '') {
        setErrorMessage(t('Please_enter_a_supplier') + '.');
        return;
      } else {
        setInProgress(t('Get_format_in_progress'))
        FetchApiFormatsBySupplier(supplierInput, setErrorMessage, t, dataProject, setInProgress, setFormats);
      }
    } else {
      setErrorMessage(t('Required_rights_to_perform_this_action'));
    }
  };

  const handleDeleteFormat = async (data, formats, index) => {
    setSuccessMessageDeletion("")
    const confirmDelete = window.confirm(`${t("Are_you_sure_you_want_to_delete")} ${data.name}?`);
    if (confirmDelete) {
      FetchApiDeleteReference(data, setSuccessMessageDeletion, t, dataProject, setErrorMessage);
    }
    formats.splice(index, 1);
    setFormats(formats);
  }

  const handleUpdateFormat = (format) => {
    navigate(`/modification-format/${format.name}/${format.file_type}`);
  }

  return (
    <>
        <div className="container-fluid mt-5 d-flex justify-content-center">
            <div className="boxed">
                <form onSubmit={handleSubmitForm} className="form-control text-center shadow p-3 mb-5 bg-body rounded form-style">
                    <div className="row gx-5">
                        <div className="col-md-12 my-3">
                        <label htmlFor="supplier" className="mb-3 fw-bold">{t('Supplier')} :</label>
                        <select
                        className="form-control mt-2 mb-3"
                        value={supplierInput}
                        onChange={handleInputSupplier}
                        >
                            <option className="text-center" disabled value="">{t("Select_supplier")}</option>
                            {supplierNameList.map((supplierName, index) => (
                                <option className="text-center" key={index} value={supplierName}>{supplierName}</option>
                            ))}
                        </select>
                        {errorMessage && (
                            <div className="alert alert-warning mt-4 fw-bold">{errorMessage}</div>
                        )}
                        {inProgress && (
                            <div className="alert alert-primary mt-4 fw-bold">{inProgress}</div>
                        )}
                        {successMessageDeletion && (
                            <div className="alert alert-success mt-4 fw-bold">{successMessageDeletion}</div>
                        )}
                        </div>
                    </div>
                    <button type="submit" className="btn  mb-3">{t('Validate')}</button>
                </form>
            </div>
        </div>
        {formats.length > 0 && (
          <div className="table-responsive">
              <table className="table table-hover table-bordered text-center table-reference-custom">
                  <thead>
                  <tr>
                    {(userGroup === "super-admin" || userGroup === "admin") && (
                        <>
                            <th className="th-custom"></th>
                            <th className="th-custom"></th>
                        </>
                    )}
                    {formats.length > 0 && Object.keys(formats[0]).map((key, index) => {
                        return <th className="th-custom" key={index}>{t(t(key.charAt(0).toUpperCase() + key.slice(1)))}</th>;
                    })}
                  </tr>
                  </thead>
                  <tbody>
                  {formats.map((data, index) => {
                      return (
                      <tr key={index}>
                          {(userGroup === "super-admin" || userGroup === "admin") && (
                            <>
                              <td>
                                  <Button className="p-0 mx-2" onClick={() => handleUpdateFormat(data)}>
                                    <TbEdit size={24} />
                                  </Button>
                              </td>
                              <td>
                                  <Button className="p-0 mx-2" onClick={() => handleDeleteFormat(data, formats, index)}>
                                    <TbTrashXFilled size={24} />
                                  </Button>
                              </td>
                            </>
                          )}
                          {Object.values(data).slice(0, 26).map((value, innerIndex) => {
                              return (
                                  <td key={innerIndex}>
                                      {Array.isArray(value) ? (
                                          value.map((item, itemIndex) => (
                                              <div key={itemIndex}>
                                                  {typeof item === 'boolean' ? (item ? t("Yes") : t("No")) : typeof item === 'object' ? JSON.stringify(item) : item}
                                              </div>
                                          ))
                                      ) : (
                                          typeof value === 'boolean' ? (value ? t("Yes") : t("No")) : typeof value === 'object' ? JSON.stringify(value) : value
                                      )}
                                  </td>
                              );
                          })}
                          {Object.values(data).slice(26, 27).map((allObj) => {
                            return (
                              <td>
                                <table className="table table-bordered text-center">
                                    <thead>
                                      <tr>
                                          <th className="th-custom">{t('Column_name')}</th>
                                          <th className="th-custom">{t('Column_type')}</th>
                                          <th className="th-custom">{t('Output_field')}</th>
                                          <th className="th-custom">{t('Is_required')}</th>
                                          <th className="th-custom">{t('Start_of_length')}</th>
                                          <th className="th-custom">{t('End_of_length')}</th>
                                      </tr>
                                    </thead>
                                  <tbody>
                                    {Object.entries(allObj).map(([key, objValue], index) => {
                                      return (
                                        <>
                                            <tr key={index}>
                                                <td>
                                                    {key}
                                                </td>
                                                <td>
                                                    {t(objValue.column_type)}
                                                </td>
                                                <td>
                                                    {t(objValue.output_field)}
                                                </td>
                                                <td>
                                                    {objValue.is_required === true ? t("Yes") : t("No")}
                                                </td>
                                                <td>
                                                    {objValue.start_of_length}
                                                </td>
                                                <td>
                                                    {objValue.end_of_length}
                                                </td>
                                            </tr>
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            );
                          })}
                          {Object.values(data).slice(27, 28).map((allObj) => {
                            return (
                              <td>
                              <table className="table table-bordered text-center">
                                  <thead >
                                  <tr>
                                      <th className="th-custom">{t('Catalog_name')}</th>
                                      <th className="th-custom">{t('Output_type')}</th>
                                      <th className="th-custom">{t('Finished_product')}</th>
                                      <th className="th-custom">{t('Sort')}</th>
                                      <th className="th-custom">{t('Sort_by')}</th>
                                      <th className="th-custom">{t('Exclude_column')}</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                    {Object.values(allObj).map((objValue, objIndex) => {
                                      return (
                                        <>
                                            <tr key={objIndex}>
                                            <td>
                                                {objValue.catalog_name}
                                            </td>
                                            <td>
                                                {objValue.output_type}
                                            </td>
                                            <td>
                                                {objValue.finished_product === true ? t("Yes") : t("No")}
                                            </td>
                                            <td>
                                                {objValue.sort === true ? t("Yes") : t("No")}
                                            </td>
                                            <td>
                                                {objValue.sort_by}
                                            </td>
                                            <td>
                                                {
                                                  objValue.exclude_column ? (
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                      {Object.keys(objValue.exclude_column).map((key) => {
                                                          return (
                                                            <span  key={key}>
                                                                <br></br>
                                                                  {key === 0 ? t(objValue.exclude_column[key]) : `${t(objValue.exclude_column[key])}`}
                                                            </span>
                                                          );
                                                      })}
                                                    </div>
                                                  ) : ''
                                                }
                                            </td>
                                            </tr>
                                        </>
                                      );
                                    })}
                                  </tbody>
                              </table>
                              </td>
                            );
                          })}
                      </tr>
                      );
                  })}
                  </tbody>
              </table>
          </div>
        )}
    </>
  );
}

async function FetchApiFormatsBySupplier(supplier, setErrorMessage, t, dataProject, setInProgress, setFormats) {
  let url = `${dataProject.startUrl}/formats/supplier?supplier=${supplier}`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `BearerCognito ${dataProject.cognitoToken}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })
  let dataResult;
  try {
    const data = await response.json();
    dataResult = data?.result;
  } catch (error) {
    setErrorMessage(t('Unexpected_error'));
  }

  setInProgress('')
  if (!response.ok) {
    if (response.status === 0) {
        setErrorMessage(`${t('Download_failed_due_to_a_connection_problem')}.`);
    } else if (response.status === 500) {
        setErrorMessage(t('Unexpected_error'));
    } else if (response.status === 401) {
        setErrorMessage(t('Unauthorized'));
        console.log(t('Unauthorized'));
        Auth.signOut()
    } else if (response.status === 404) {
        setErrorMessage(t('No_format_found'));
    } else {
        setErrorMessage(t('Unexpected_error'));
    }
  } else {
    if (!dataResult.formats || dataResult.formats.length === 0) {
      setErrorMessage(t('No_format_found'));
    } else {
      setFormats(dataResult.formats);
    }
  }
}   

async function FetchApiDeleteReference(data, setSuccessMessageDeletion, t, dataProject, setErrorMessage) {
  let url = `${dataProject.startUrl}/delete-format`;
  
  // Process file_output object: if any entry has supplier=null, set it to data.supplier
  if (data.file_output && typeof data.file_output === 'object') {
    Object.keys(data.file_output).forEach(key => {
      if (data.file_output[key].supplier === null) {
        data.file_output[key].supplier = data.supplier;
      }
    });
  }
  
  try {
    const response = await fetch(url, { 
      headers: {
        "Authorization": `BearerCognito ${dataProject.cognitoToken}`,
      },
      method: 'DELETE',
      body: JSON.stringify({
        'format_name': data.name, 
        'file_type': data.file_type, 
        'file_output': data.file_output
      }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        setErrorMessage(t("Not_authorised"));
      } else if (response.status === 404) {
        setErrorMessage(t('No_format_found'));
      } else {
        setErrorMessage(t("Unexpected_error"));
      }
    } else {
      setSuccessMessageDeletion(`${('Format')} ${data.name} ${t('has_been_deleted')}.`);
    }
  } catch (error) {
    console.error(error);
    setErrorMessage(t('Unexpected_error'));
  }
}

export default FormatsBySupplierComponent;
