import React from "react";
import useT from '../../Traduction';

function ResetButton({ type, format, setExtension, setLines, setFileSeparator, setHasHeader, setFileInformationsCompleted, setFormatName, setFormatAndSupplierNameCompleted, setUnitPrice, setFileConfirmed, setFixedFieldSize, setFieldMapping, setFileOutput, setFieldMappingConfirmed, setFileOutputConfirmed, setFileEncoding, setSupplier, setQuoteCharacter, setHeadersRows, setFixedFieldSizeColumnObject, setCountry, setLanguage, setMeasurementUnitConfirmed, setMeasurementUnitFields, setLengthUnit, setHeightUnit, setVolumeUnit, setWeightUnit, setWidthUnit, setSkipRows, setSkipEndRows, setSelectedSheets, setExcelSelectAll }) {
    const t = useT();
    const handleReset = () => {
        if (type === "creation") {
            // Reset for creation
            setExtension("");
            setLines([]);
            setFileSeparator("");
            setHasHeader(true);
            setFileInformationsCompleted(false);
            setFormatName("");
            setFormatAndSupplierNameCompleted(false);
            setUnitPrice("");
            setFileConfirmed(false);
            setFixedFieldSize(false);
            setFieldMapping([]);
            setFileOutput([]);
            setFieldMappingConfirmed(false);
            setFileOutputConfirmed(false);
            setFileEncoding("");
            setSupplier("");
            setQuoteCharacter("");
            setHeadersRows([]);
            setFixedFieldSizeColumnObject([]);
            setCountry("FR");
            setLanguage("FR");
            setMeasurementUnitConfirmed(false);
            setMeasurementUnitFields([]);
            setLengthUnit("");
            setHeightUnit("");
            setVolumeUnit("");
            setWeightUnit("");
            setWidthUnit("");
            setSkipEndRows(0);
            setSkipRows(0);
            setSelectedSheets([]);
            setExcelSelectAll(false);
        } else {
            // Reset for update
            setExtension(format.file_type.toUpperCase());
            setLines([]);
            setFileSeparator(format.separator);
            setHasHeader(format.has_headers);
            setFileInformationsCompleted(false);
            setFormatName(format.name);
            setFormatAndSupplierNameCompleted(true);
            setUnitPrice(format.unit_price);
            setFileConfirmed(false);
            setFixedFieldSize(format.fixed_field_size === 1 ? true : false);
            setFieldMapping(format.fields_mapping);
            setFileOutput(format.file_output);
            setFieldMappingConfirmed(false);
            setFileOutputConfirmed(false);
            setFileEncoding(format.encoding);
            setSupplier(format.supplier);
            setQuoteCharacter(format.quoting_char);
            setHeadersRows([]);
            setFixedFieldSizeColumnObject([]);
            setCountry(format.country);
            setLanguage(format.language);
            setMeasurementUnitConfirmed(false);
            setMeasurementUnitFields([]);
            setLengthUnit(format.unit_length);
            setHeightUnit(format.unit_height);
            setVolumeUnit(format.unit_volume);
            setWeightUnit(format.weight_unit);
            setWidthUnit(format.width_unit);
            setSkipEndRows(format.skip_end_rows ?? 0);
            setSkipRows(format.skip_rows ?? 0);
            setSelectedSheets(format.excel_sheets_names);
            setExcelSelectAll(format.excel_sheets_all);
        }
    };

    const buttonText = type === "creation" ? t("Reset_creation") : t("Reset_update");

    return (
        <div className="d-flex justify-content-center">
            <button type="submit" className="btn mt-3" onClick={handleReset}>{buttonText}</button>
        </div>
    );
}

export default ResetButton;