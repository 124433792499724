import React from 'react'
import Header from '../../components/Header'
import UpdateFormat from '../../components/UpdateFormat/UpdateFormat'
import Title from '../../components/Title'
import ButtonLink from '../../components/ButtonLink'
import Footer from '../../components/Footer'
import useT from '../../components/Traduction'


function UpdateFormatPage({ dataProject }) {
  const t = useT()
    return (
      <>
        <div className='min-height'>
          <Title titleName={t('Update_format')} />
          <Header link='/format-fournisseur' titleHeader={t('Update_format')} linkText={t('Back')} />
          <ButtonLink link='/format-fournisseur' 
            linkText={t('Back')} 
            className='button-small-back'
          />
          <UpdateFormat dataProject={dataProject} />
        </div>
        <Footer />
      </>

    )
  }
  
export default UpdateFormatPage